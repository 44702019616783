import cn from 'classnames';
import {
  ComponentPropsWithoutRef,
  FC,
  forwardRef,
  InputHTMLAttributes,
  MutableRefObject,
  useCallback,
  useRef,
  useState,
} from 'react';
import { FieldError } from 'react-hook-form';

import randomId from '@lib/random-id';

import Text from '../Text';

import style from './InputText.module.scss';

export interface Props extends InputHTMLAttributes<HTMLInputElement>, ComponentPropsWithoutRef<any> {
  className?: string;
  id?: string;
  onChange?: (...args: any[]) => any;
  label?: string;
  type?: 'text' | 'number' | 'email' | 'password' | 'search' | 'tel' | 'url';
  helperText?: string;
  error?: FieldError;
  onBlur?: (e: any) => void;
  'data-cy'?: string;
  handleAutofill?: (e: any) => void;
  icon?: JSX.Element;
}

function isMutableRefObject(ref: any): ref is MutableRefObject<any> {
  return !!ref?.current;
}

const InputText: FC<Props> = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    children,
    onChange,
    onBlur,
    label,
    type = 'text',
    id,
    defaultValue,
    helperText,
    error,
    disabled,
    autoComplete = 'on',
    handleAutofill,
    icon,
    ...rest
  } = props;
  const rootClassName = cn(style.root, {}, className);

  const [inputId] = useState(id ?? randomId());
  const inputRef = useRef<HTMLInputElement>(null);
  const [floating, setFloating] = useState(false);
  const value = inputRef.current?.value || defaultValue;

  const callbackRef = useCallback(
    (element: HTMLInputElement | null) => {
      (inputRef as any).current = element;
      if (autoComplete === 'off') {
        element?.setAttribute('autocomplete', 'off');
      }
      if (ref && !isMutableRefObject(ref)) {
        ref(element);
      }
    },
    [ref, autoComplete]
  );

  const handleOnChange = (e: any) => {
    if (e.target.value) {
      setFloating(true);
    }
    if (onChange) {
      onChange(e);
    }
  };

  const handleOnBlur = (e: any) => {
    setFloating(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleAnimation = () => {
    handleAutofill?.(true);
  };

  const isFocused = floating || !!value || !!error;

  return (
    <div className={rootClassName}>
      {label && (
        <label
          htmlFor={inputId}
          className={cn(
            style.label,
            { [style.focusedLabel]: isFocused },
            { [style.isError]: !!error },
            { [style.isDisabled]: !!disabled }
          )}
        >
          {label}
        </label>
      )}
      <input
        type={type}
        ref={callbackRef}
        id={inputId}
        name={inputId}
        className={cn(
          style.input,
          { [style.focusedInput]: isFocused, [style.withoutLabel]: label },
          { [style.errorInput]: !!error }
        )}
        onAnimationStart={handleAnimation}
        onChange={handleOnChange}
        onFocus={() => setFloating(true)}
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        disabled={disabled}
        placeholder={isFocused ? '' : label}
        autoComplete={autoComplete}
        {...rest}
        onBlur={handleOnBlur}
      />
      {icon}
      {(helperText || error?.message) && (
        <Text
          variant="text-4"
          data-cy={rest['data-cy'] ? `${rest['data-cy']}-helper-text` : undefined}
          className={cn(style.helperText, { [style.isError]: !!error })}
          aria-live="assertive"
        >
          {helperText || error?.message}
        </Text>
      )}
    </div>
  );
});

InputText.displayName = 'InputText';

export default InputText;
