import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import throttle from 'lodash/throttle';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import {
  FC,
  FocusEvent,
  FocusEventHandler,
  FormEventHandler,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import checkMobileView from '@lib/check-mobile-view';
import { trackSearchEvent } from '@lib/gtag';

import SearchInput from './SearchInput';
import SearchOverlay from './SearchOverlay';

import s from './Searchbar.module.scss';

interface Props {
  key?: string;
  className?: string;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  onToggle?: (state: boolean) => void;
}

const noop = () => {};

const Searchbar: FC<Props> = ({ key, className, onFocus = noop, onBlur = noop, onToggle = noop }) => {
  const router = useRouter();
  const { t } = useTranslation(['product']);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(router.query?.q || '');
  const [isMobile, setIsMobile] = useState(checkMobileView('lg'));
  const prefetched = useRef<boolean>(false);

  const onFocusHandler = useCallback(
    (e: FocusEvent) => {
      if (!prefetched.current) {
        router.prefetch('/products');
        prefetched.current = true;
      }
      setIsFocused(true);
      onFocus(e);
    },
    [onFocus, router]
  );

  const onBlurHandler = useCallback(
    (e: FocusEvent) => {
      setIsFocused(false);
      onBlur(e);
    },
    [onBlur]
  );

  const redirectToProductSearch = () => {
    trackSearchEvent(value.toString());
    if (value !== router.query.q) {
      router.push({
        pathname: '/products',
        query: {
          q: value,
        },
      });
    }

    inputRef.current?.blur();
  };

  const onClear = (e: MouseEvent) => {
    e.preventDefault();
    setValue('');
    inputRef.current?.blur();
    router.push({ pathname: '/products' });
  };

  const onResize = () => {
    setIsMobile(checkMobileView('md'));
  };

  const onSearchClickHandler: FormEventHandler = (e) => {
    e.preventDefault();
    if (isFocused) {
      redirectToProductSearch();
    } else {
      inputRef.current?.focus();
    }
  };

  useEffect(() => {
    const throttledHandler = throttle(onResize, 200);
    window.addEventListener('resize', throttledHandler);
    return () => {
      window.removeEventListener('resize', throttledHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={onSearchClickHandler} role="search">
      {isMobile ? (
        <SearchOverlay
          inputRef={inputRef}
          value={value as string}
          onToggle={onToggle}
          onChange={(val) => setValue(val)}
          onSearch={() => redirectToProductSearch()}
          onClear={onClear}
          onFocus={() => {
            if (!prefetched.current) {
              router.prefetch('/products');
              prefetched.current = true;
            }
          }}
        />
      ) : (
        <div key={key} className={cn(s.root, className, isFocused ? s.isFocused : '')}>
          <button
            data-testid="search-button"
            aria-label={t('product:search.placeholder.normal')}
            type="submit"
            className={cn(s.iconContainer, isFocused ? s.isFocused : '')}
          >
            <FontAwesomeIcon className={s.searchIcon} icon={faSearch} title={t('product:search.placeholder.normal')} />
          </button>
          <SearchInput
            inputRef={inputRef}
            value={value as string}
            placeholder={t(`product:search.placeholder.${isFocused ? 'inFocus' : 'normal'}`)}
            onChange={(val) => setValue(val)}
            onSearch={() => redirectToProductSearch()}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            onClear={onClear}
            className={s.input}
            clearOnMouseDown
            btnClassName={cn({ [s.clearOnFocus]: isFocused })}
          />
        </div>
      )}
    </form>
  );
};

export default Searchbar;
