import cn from 'classnames';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Searchbar } from '@components/common';
import ClickOutside from '@lib/click-outside/click-outside';

import { CartButton, LogoButton, MobileMenuButton } from '../buttons';
import { createUrlList } from '../createUrlList';
import { MobileDropDownTransitiveProps, NavigationProps } from '../types';
import { Dropdown } from './Dropdown';

import s from './MobileNavigation.module.scss';

type Props = Pick<NavigationProps, 'linksOrMenus'> & MobileDropDownTransitiveProps;

export const MobileNavigation: FC<Props> = ({ linksOrMenus, calcDropDownTop }) => {
  const list = useMemo(() => createUrlList(linksOrMenus), [linksOrMenus]);
  const [open, setOpen] = useState(false);

  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const onSearchFocus = useCallback(() => {
    setIsSearchFocused(true);
    setOpen(false);
  }, []);

  const onSearchBlur = useCallback(() => {
    setIsSearchFocused(false);
  }, []);

  const onSearchToggle = useCallback((state: boolean) => {
    if (state) {
      setOpen(false);
    }
  }, []);

  const close = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add('mobile-menu-open');
    } else {
      document.body.classList.remove('mobile-menu-open');
    }

    return () => {
      document.body.classList.remove('mobile-menu-open');
    };
  }, [open]);

  return (
    <ClickOutside active={open} onClick={close}>
      <header className="lg:hidden bg-white">
        <div>
          <nav className={s.navigationBar}>
            <MobileMenuButton
              onClick={() => {
                setOpen((v) => !v);
                setIsSearchFocused(false);
              }}
              className="justify-self-start"
            />

            <LogoButton className={cn(s.logo, 'justify-self-center p-1')} onClick={close} />

            <div className="flex flex-row items-center justify-self-end space-x-2">
              <div className={isSearchFocused ? s.isSearchFocused : s.search}>
                <Searchbar onBlur={onSearchBlur} onFocus={onSearchFocus} onToggle={onSearchToggle} />
              </div>

              <CartButton />
            </div>
          </nav>

          {open && <Dropdown list={list} close={close} top={calcDropDownTop()} />}
        </div>
      </header>
    </ClickOutside>
  );
};
