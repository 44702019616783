import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import { trackNavBarEvent } from '@lib/gtag';

import s from './button.module.scss';

type Props = {
  onClick?: () => void;
};

export const HelpButton = ({ onClick }: Props) => {
  const { t } = useTranslation('common');
  return (
    <a
      href="https://support.thetileapp.com/"
      target="_blank"
      rel="noreferrer"
      title={`${t('common:navigation.helpPage')} ${t('common:screenReader.openInNewTab')}`}
      onClick={() => {
        trackNavBarEvent('support');
        onClick?.();
      }}
      className={classNames(s.text, s.mobileSecondaryLink)}
    >
      <FontAwesomeIcon icon={faQuestionCircle} className={s.mobileSecondaryLinkIcon} aria-hidden />
      <span>{t('common:navigation.help')}</span>
    </a>
  );
};
