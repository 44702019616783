import cn from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ComponentType, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useDeepEffect from 'use-deep-compare-effect';

import type { Page } from '@commerce/types/page';
import { Product } from '@commerce/types/product';
import type { CategoryTreeItem } from '@commerce/types/site';
import { LinkLoginView, PasswordLoginView } from '@components/auth';
import { CartOverlay } from '@components/cart';
import { Footer, MessageBar } from '@components/common';
import I18nDetectorDialog from '@components/common/I18nSelector/I18nDetectorDialog';
import QueryHandler from '@components/common/QueryHandler';
import { CompactNavbar, Navigation, NavigationProps } from '@components/navigation';
import { LoadingDots, Modal } from '@components/ui';
import { GlobalQueryString, useUI } from '@components/ui/context';
import { isModalOpenableViaQueryParams, ModalViews } from '@components/ui/modals';
import { getStringParam } from '@lib/http';
import { deleteQueryString } from '@lib/query-string';
import { FCWithChildren } from '@lib/types/react-utilities';
import { Maybe } from '@lib/utility-types';

import LoadingView from '../LoadingView';
import { MessageBarHeightHandle, ModuleMessageBar } from '../types/ModuleMessageBar';
import { LayoutMessageBarVisibilityManager } from './LayoutMessageBarVisibilityManager';

import s from './Layout.module.scss';

const Loading = () => (
  <div className="w-80 h-80 flex items-center text-center justify-center p-3">
    <LoadingDots />
  </div>
);

const dynamicProps = {
  loading: () => <Loading />,
};

const LinkLoginSentView = dynamic(() => import('@components/auth/Login/LinkLoginSentView'), { ...dynamicProps });

const SignUpView = dynamic(() => import('@components/auth/SignUp/SignUpView'), { ...dynamicProps });

const ResendEmailView = dynamic(() => import('@components/auth/SignUp/ResendEmailView'), { ...dynamicProps });

const RegionSwitcherConfirmation = dynamic(
  () => import('@components/common/I18nSelector').then((m) => m.RegionSwitcherConfirmation as any),
  { ...dynamicProps }
);

const ChangeEmailVerificationModal = dynamic(() => import('@components/account/ChangeEmailVerificationModal'), {
  ...dynamicProps,
});

const LogoutConfirmation = dynamic(() => import('@components/auth/Logout/LogoutConfirmation'), {
  ...dynamicProps,
});

export interface Props {
  isErrorPage: boolean;
  pageProps: {
    pages?: Page[];
    categories: CategoryTreeItem[];
    messageBarData: ModuleMessageBar | null;
    footerData: any;
    navigation: NavigationProps | null;
    product?: Product | null;
  };
}

const modalKeyToView: Partial<Record<ModalViews, ComponentType<any>>> = {
  LINK_LOGIN_VIEW: LinkLoginView,
  LINK_LOGIN_SENT_VIEW: LinkLoginSentView,
  PASSWORD_LOGIN_VIEW: PasswordLoginView,
  SIGNUP_VIEW: SignUpView,
  RESEND_VIEW: ResendEmailView,
  SWITCH_REGION: RegionSwitcherConfirmation,
  CHANGE_EMAIL_VERIFICATION: ChangeEmailVerificationModal,
  LOADING_VIEW: LoadingView,
  LOGOUT_CONFIRM_VIEW: LogoutConfirmation,
};

const Layout: FCWithChildren<Props> = ({
  children,
  pageProps: { messageBarData, footerData, navigation },
  isErrorPage,
}) => {
  const { t } = useTranslation('common');
  const { displayModal, closeModal, modal, displayCartOverlay, setModal, openModal, isDownloadPage, product } = useUI();

  const router = useRouter();
  const { query, isReady: routerReady, pathname } = router;
  const [isReady, setIsReady] = useState(false);
  const prevErrorPageRef = useRef<boolean | null>(null);
  const shouldUseCompactHeader = pathname.substring(1).startsWith('cart');
  const messageBarRef = useRef<MessageBarHeightHandle>(null);

  const inApp = Number(query[GlobalQueryString.IN_APP]) === 1;
  const userLocale = query[GlobalQueryString.USER_LOCALE] as Maybe<string>;

  const redirectPathAfterLogin = useMemo(
    () => (GlobalQueryString.NEXT in query ? query[GlobalQueryString.NEXT] : ''),
    [query]
  );
  const accountVerified = useMemo(() => GlobalQueryString.VERIFIED in query, [query]);
  const isHomePage = useMemo(() => pathname === '/[[...slug]]' && !query.slug, [pathname, query.slug]);
  const removeQueryString = useCallback(
    (toReplace: string[]) => {
      router.replace({ pathname: router.pathname, query: deleteQueryString(router, toReplace) }, undefined, {
        shallow: true,
      });
    },
    [router]
  );

  const messageBarHeightMeasure = useCallback(() => messageBarRef.current?.heightMeasure(), [messageBarRef]);

  useDeepEffect(() => {
    const modalQuery = getStringParam(query.modal);
    if (routerReady && isModalOpenableViaQueryParams(modalQuery)) {
      setModal(modalQuery, { ...query });
      openModal();
    }
  }, [query, routerReady]);

  useEffect(() => {
    setIsReady(routerReady);
  }, [routerReady]);

  // workaround for https://github.com/vercel/next.js/issues/35990 bug: router.isReady will stay as `false` in custom error page
  // if page is navigated from an error page to a normal page, don't block render
  useEffect(() => {
    if (prevErrorPageRef.current && !isErrorPage) {
      setIsReady(true);
    }
    prevErrorPageRef.current = !!isErrorPage;
  }, [isErrorPage]);

  useEffect(() => {
    // to open login modal when `?next=` redirect path is set
    if (setModal && openModal && redirectPathAfterLogin && isHomePage) {
      setModal('PASSWORD_LOGIN_VIEW', { showAccountVerified: accountVerified, redirectToPath: redirectPathAfterLogin });
      openModal();
      removeQueryString(['verified', 'next']);
    }
  }, [isHomePage, redirectPathAfterLogin, accountVerified, setModal, openModal, removeQueryString]);

  const ModalComponent = modal.view && modal.view in modalKeyToView ? modalKeyToView[modal.view as ModalViews] : null;

  // still need to check `router.isReady` for some pre-rendered builds + bundle size, removing this check causes more headache unfortunately
  return isReady || isErrorPage ? (
    <div className={cn(s.root)}>
      <nav className={s.nav} aria-label={t('navigation.mainMenu')} data-navigation-bar>
        <a href="#mainContent" className="sr-only focus:not-sr-only focus:absolute focus:z-10">
          Skip to main content
        </a>
        <LayoutMessageBarVisibilityManager product={product} isDownloadPage={isDownloadPage}>
          <MessageBar
            content={messageBarData?.messageBarContent}
            backgroundColor={messageBarData?.messageBarBackgroundColor}
            textColor={messageBarData?.messageBarTextColor}
            ref={messageBarRef}
          />
        </LayoutMessageBarVisibilityManager>
        {!isDownloadPage &&
          (shouldUseCompactHeader ? (
            <CompactNavbar />
          ) : (
            <Navigation {...(navigation ?? {})} calcDropDownTop={messageBarHeightMeasure} />
          ))}
        {inApp && displayCartOverlay && !isDownloadPage && <CartOverlay hideOverlayArrow />}
        {userLocale && <I18nDetectorDialog userLocale={userLocale} />}
      </nav>
      <main id="mainContent" className="fit">
        {children}
      </main>

      {!inApp && !!footerData && !isDownloadPage && <Footer data={footerData} />}

      <Modal className={modal?.className} open={displayModal} onClose={closeModal} hideCloseBtn={modal?.hideCloseBtn}>
        {ModalComponent && <ModalComponent {...modal?.data} />}
      </Modal>

      <QueryHandler />
    </div>
  ) : null;
};

export default Layout;
